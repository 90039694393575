import React from 'react';

import door from 'assets/images/404.png';
import { Link } from 'gatsby';
import { paths } from 'utilities/routes';
import { Img } from 'utilities/components/img/component';

import './view.scss';

const ErrorPageView = () => (
    <section className="error-page__container">
        <div className="container__image-wrapper">
            <Img className="image-wrapper__error-image" src={ door } alt="" />
        </div>
        <h3 className="container__error-header">
            Błąd 404
        </h3>
        <p className="container__error-text">
            Strona, której szukasz nie została odnaleziona.
        </p>
        <div className="container__error-links">
            <p>
                Spróbuj otworzyć inne drzwi:
            </p>
            <ul className="error-links__list">
                <li className="list__link">
                    <Link to={ paths.home }>
                        Strona główna
                    </Link>
                </li>

                <li className="list__link">|</li>

                <li className="list__link">
                    <Link to={ paths.drzwiWewnetrzne }>
                        Drzwi wewnętrzne
                    </Link>
                </li>

                <li className="list__link">|</li>

                <li className="list__link">
                    <Link to={ paths.gdzieKupic }>
                        Gdzie kupić?
                    </Link>
                </li>
            </ul>
        </div>
    </section>
);

export default ErrorPageView;