import React from 'react';

import { Navigation } from '../utilities/components/navigation/component';
import ErrorPageView from 'utilities/components/404/component/view';
import { Footer } from '../utilities/components/footer/component';
import { MESSENGER_APP_ID, MESSENGER_PAGE_ID } from 'utilities/consts';
import MessengerCustomerChat from 'react-messenger-customer-chat';

import '../assets/styles/vendors.scss';
import '../assets/styles/normalize.scss';
import '../assets/styles/fonts.scss';

import SEO from 'utilities/components/seo';

const ErrorPage = () => (
    <main className="error-page">
        <SEO title="Nie znaleziono takiej strony | Barański Drzwi" />
        <Navigation />
        <ErrorPageView />
        <Footer />
        <MessengerCustomerChat pageId={ MESSENGER_PAGE_ID } appId={ MESSENGER_APP_ID } />
    </main>
);
export default ErrorPage;
